import { Routes, Route } from 'react-router-dom'
import Registration from './screens/Registration'
import Login from './screens/Login'
import VerifyOtp from './screens/verifyOtp'
import Dashboard from './screens/Dashboard'
import Success from './screens/Registration/success'
import { RequiresAuth } from './utils/requiresAuth'
import { CheckUserLoggedIn } from './utils/userLoggedIn'
import NotFound from './screens/404'
import Home from './screens/Home/Home'

const RoutesComponent = props => {
  return (
    <Routes>
      <Route path="/*" exact element={<NotFound />} />

      <Route path="/" element={<Home appRef={props?.appRef} />} />
      <Route
        path="/registration"
        element={
          /* <CheckUserLoggedIn> */
          <Registration appRef={props?.appRef} />
          /* </CheckUserLoggedIn> */
        }
      />
      <Route path="/verifyOtp" exact element={<VerifyOtp />} />
      <Route
        path="/login"
        exact
        element={
          <CheckUserLoggedIn>
            <Login />
          </CheckUserLoggedIn>
        }
      />
      <Route
        path="/dashboard"
        exact
        element={
          <RequiresAuth>
            <Dashboard />
          </RequiresAuth>
        }
      />
      <Route
        path="/registration/success/:policy_number"
        exact
        element={<Success appRef={props?.appRef} />}
      />
    </Routes>
  )
}

export default RoutesComponent

/* eslint-disable prettier/prettier */
import { useState, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import {
  Input,
  Col,
  Row,
  Form,
  Typography,
  Button,
  DatePicker,
  Select,
  Upload,
  Descriptions,
} from 'antd'
import moment from 'moment'
import _ from 'lodash'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate } from 'react-router-dom'
import publicIp from 'public-ip'
import Step3 from './step3'
import './style.css'
import './style.scss'
import { SiteConstants } from '../../store/constants/siteconstants'
import {
  getStateList,
  userRenewAction,
  userRegistrationAction,
  GetSettingData,
  setLoaderAtUserRegistrationTime,
} from '../../store/actions/user'
import pdfLogo from '../../assets/image/pdfLogo1.png'
import NoImage from '../../assets/image/NoImage.png'
import { verifyCaptchaApi } from '../../services/captcha'
import { NullTextCheckForm, disableScroll } from '../../utils/globalFunction'

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input

// Image Display Component
export function ImageDisplayComponent(params) {
  return (
    <>
      <div className="image-wrapper">
        <img
          className="profImage"
          src={params?.ImageName}
          alt=""
          onError={e => (e.target.style.display = 'none')}
          onLoad={e => (e.target.style.display = 'block')}
        />
      </div>
    </>
  )
}

// Pdf Display Component
export function PdfDisplayComponent(para) {
  return (
    <>
      {para?.name1 && para?.name1.split('.').pop().slice(0, 3) === 'pdf' ? (
        // default edit pdf
        <a href={para?.name1} target="_blank" rel="noopener noreferrer">
          <img
            className="profImage"
            style={{
              marginTop: '10px',
              height: 'auto',
            }}
            src={pdfLogo}
            alt=""
            onError={e => {
              e.currentTarget.src = NoImage
            }}
          />
        </a>
      ) : (
        para?.name1 && (
          <div
            className={`image-wrapper ${para.name2 ? 'pdf' : ''}`}
            onClick={() => para?.name5(para?.name1, _.has(para.name3, para?.name4))}
          >
            {para.name3 &&
              _.has(para.name3, para?.name4) &&
              para.name2 &&
              para.name4 !== 'signature_image' && (
                //  pdf ( onchage )
                <img
                  className="profImage"
                  alt=""
                  src={pdfLogo}
                  onError={e => {
                    e.currentTarget.src = NoImage
                  }}
                />
              )}
          </div>
        )
      )}
    </>
  )
}

const Registration = function (props) {
  const {
    RecaptchaKey,
    gigSureLabel,
    acceptOnlyFileType,
    ImagePdfFileAllowedMessage,
    acceptImagePdfFileType,
    imageDisplayType,
  } = SiteConstants
  const formRef = useRef()
  const captcha = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoading = useSelector(store => store?.userData?.registrationLoader)

  const settingData = useSelector(store => store?.userData?.setting_data)

  const [hideFacilitator, setHideFacilitator] = useState(false)
  const [hideAgent, setHideAgent] = useState(true)

  const [isCaptchaVerify, setIsCaptchaVerify] = useState(false)
  const [hideVehicle, setHideVehicle] = useState(false)

  const [facilitatorValidation, setFacilitatorValidation] = useState(true)
  const [AgentValidation, setAgentValidation] = useState(true)

  const [ipAddress, setIpAddress] = useState()
  const [isSubmitBtnPressed, setIsSubmitBtnPressed] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  // const [isOwnProperty, setOwnPropertyType] = useState(false)

  const [stateLists, setStateLists] = useState([])

  const [aadharCardFront, setAadharCardFront] = useState('')
  const [aadharCardFrontImageData, setAadharCardFrontImageData] = useState('')

  const [aadharCardBack, setAadharCardBack] = useState('')
  const [aadharCardBackImageData, setAadharCardBackImageData] = useState('')

  const [panCardFront, setPanCardFront] = useState('')
  const [panCardFrontImageData, setPanCardFrontImageData] = useState('')

  const [nomineeAadharFront, setNomineeAadharFront] = useState('')
  const [nomineeAadharFrontImageData, setNomineeAadharFrontImageData] = useState('')

  const [nomineeAadharBack, setNomineeAadharBack] = useState('')
  const [nomineeAadharBackImageData, setNomineeAadharBackImageData] = useState('')

  const [bankDetails, setBankDetails] = useState('')
  const [bankDetailsImageData, setBankDetailsImageData] = useState('')

  const [signature, setSignature] = useState('')
  const [signatureImageData, setSignatureImageData] = useState('')

  const [transactionReceiptDetails, setTransactionReceiptDetails] = useState('')
  const [transactionReceiptImageData, setTransactionReceiptImageData] = useState('')

  const [stateTwoLetter, setStateTwoLetter] = useState('GJ')
  const [TwoLetterStateNumber, SetTwoLetterStateNumber] = useState('01')

  const [registrationFormData, setRegistrationFormData] = useState({})
  const [isPdfFileUploaded, setIsPdfFileUploaded] = useState({})
  const [disabledOwnPropertyOpt, setDisabledOwnPropertyOpt] = useState(false)
  const [isDisabledAllOpt, setIsDisabledAllOpt] = useState(false)
  const [isDisabledNoneOpt, setDisabledNoneOpt] = useState(false)
  const [isShowLoanField, setIsShowLoanField] = useState(false)

  const [isDisabledHealth, setisDisabledHealth] = useState(false)
  const [isDisabledHealth_None, setisDisabledHealth_None] = useState(false)

  const [isDisableOptionInsurance, setisDisableOptionInsurance] = useState(false)
  const [isDisableNoneInsurance, setisDisableNoneInsurance] = useState(false)
  const [isDisableAllInsurance, setisDisableAllInsurance] = useState(false)

  const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const [form3] = Form.useForm()

  const UserData = localStorage.getItem('userId')

  // On Change Image Check Image Is Set Or Not Check
  let [IsSetImage, setIsImage] = useState({
    user_aadhaar_card_front_img: null,
    user_aadhaar_ard_back_img: null,
    user_pan_card_img: null,
    nominee_aadhar_card_front_img: null,
    nominee_aadhar_card_back_img: null,
    passbook_cancel_cheque_img: null,
    signature_img: null,
    transaction_receipt_img: null,
  })

  // Image Validation
  let [isImagesUpdate, setisImagesUpdate] = useState({
    aadhar_img_front: false,
    aadhar_img_back: false,
    nomioni_aadhar_front: false,
    nomioni_aadhar_back: false,
    pancard_image_front: false,
    passbook_cancelled_cheque: false,
    proposer_signature: false,
    transaction_receipt_image: false,
  })

  // prettier-ignore
  const [ aadharcard_front_image_validation, setAadharcard_front_image_validation] = useState(false);
  // prettier-ignore
  const [ aadharcard_back_image_validation, setAadharcard_back_image_validation ] = useState(false);

  // prettier-ignore
  const [ nominee_aadharcard_front_image_validation, setNominee_aadharcard_front_image_validation ] = useState(false);
  // prettier-ignore
  const [ nominee_aadharcard_back_image_validation, setNominee_aadharcard_back_image_validation ] = useState(false);

  // prettier-ignore
  const [bank_details_proof_image_validation, setBank_details_proof_image_validation] = useState(false)
  // prettier-ignore
  const [signature_image_validation, setSignature_image_validation] = useState(false)

  // prettier-ignore
  const [transaction_receipt_image_validation, setTransaction_receipt_image_validation] = useState(false)

  // prettier-ignore
  useEffect(
      () => {
        aadharCardFrontImageData && setisImagesUpdate({
            ...isImagesUpdate, aadhar_img_front: true,
          });
        aadharCardBackImageData && setisImagesUpdate({
            ...isImagesUpdate, aadhar_img_back: true,
          });
          nomineeAadharFrontImageData && setisImagesUpdate({
            ...isImagesUpdate, nomioni_aadhar_front: true,
          });
          nomineeAadharBackImageData && setisImagesUpdate({
            ...isImagesUpdate, nomioni_aadhar_back: true,
          });
          panCardFrontImageData && setisImagesUpdate({
            ...isImagesUpdate, pancard_image_front: true,
          });
          bankDetailsImageData && setisImagesUpdate({
            ...isImagesUpdate, passbook_cancelled_cheque: true,
          });
          signatureImageData && setisImagesUpdate({
            ...isImagesUpdate, proposer_signature: true,
          });
          transactionReceiptImageData && setisImagesUpdate({
            ...isImagesUpdate, transaction_receipt_image: true,
          });
      
      },
      //eslint-disable-next-line
      [
        aadharCardFrontImageData,
        aadharCardBackImageData,
        panCardFrontImageData,
        nomineeAadharFrontImageData,
        nomineeAadharBackImageData,
        bankDetailsImageData,
        signatureImageData,
        transactionReceiptImageData,
      ]
    );

  useEffect(() => {
    if (currentStep === 1 || currentStep === 2 || currentStep === 3)
      dispatch(setLoaderAtUserRegistrationTime(false))
    //eslint-disable-next-line
  }, [currentStep])

  // For Renue Plan
  useEffect(() => {
    if (currentStep === 2) {
      !aadharCardFront && setAadharcard_front_image_validation(true)
      !aadharCardBack && setAadharcard_back_image_validation(true)
      !nomineeAadharFront && setNominee_aadharcard_front_image_validation(true)
      !nomineeAadharBack && setNominee_aadharcard_back_image_validation(true)
      !bankDetails && setBank_details_proof_image_validation(true)
      !signature && setSignature_image_validation(true)
    }

    if (currentStep === 4) {
      !transactionReceiptDetails && setTransaction_receipt_image_validation(true)
    }
    // eslint-disable-next-line
  }, [currentStep])

  // For Renue Plan
  useEffect(() => {
    if (UserData) {
      if (moment(new Date()).isAfter(props?.userDetails?.expDate)) {
        // TRIGGER
        onChangesFormValues(props?.userDetails?.fetchUser)

        let prefix_agent = props?.userDetails?.fetchUser?.agent_code?.split('-')?.[1]
        let prefix_facilitor = props?.userDetails?.fetchUser?.facilitator_code?.split('-')

        prefix_facilitor?.length > 1
          ? setStateTwoLetter(prefix_facilitor?.[0])
          : setStateTwoLetter('GJ')

        let prefix = props?.userDetails?.fetchUser?.name?.split('.')

        // First Form
        form?.setFieldsValue({
          facilitator: props?.userDetails?.fetchUser?.facilitator || [],

          agent_name: props?.userDetails?.fetchUser?.agent_name,
          agent_code: prefix_agent,

          facilitator_name: props?.userDetails?.fetchUser?.facilitator_name,
          facilitator_code:
            prefix_facilitor?.length > 1 ? prefix_facilitor?.[1] : prefix_facilitor?.[0],

          name: prefix?.length > 1 ? prefix?.[1]?.toString() : prefix?.[0]?.toString(),
          prefix: prefix?.length > 1 ? prefix?.[0]?.toString() + '.' : 'MR.',

          dob: moment(
            new Date(props?.userDetails?.fetchUser?.dob)?.toLocaleDateString('en-GB'),
            'DD-MM-YYYY'
          ),

          gender: props?.userDetails?.fetchUser?.gender,
          marital_status: props?.userDetails?.fetchUser?.marital_status,
          business: props?.userDetails?.fetchUser?.business,
          monthly_income: props?.userDetails?.fetchUser?.monthly_income,
          height: props?.userDetails?.fetchUser?.height,
          weight: props?.userDetails?.fetchUser?.weight,
          blood_group: props?.userDetails?.fetchUser?.blood_group,
          disease: props?.userDetails?.fetchUser?.disease,
          total_family_members: props?.userDetails?.fetchUser?.total_family_members,
          yojana_number: NullTextCheckForm(props?.userDetails?.fetchUser?.yojana_number),
          address: props?.userDetails?.fetchUser?.address,
          city: props?.userDetails?.fetchUser?.city,
          taluka: props?.userDetails?.fetchUser?.taluka,
          district: props?.userDetails?.fetchUser?.district,
          state: props?.userDetails?.fetchUser?.state,
          country: props?.userDetails?.fetchUser?.country,
          pin_code: props?.userDetails?.fetchUser?.pin_code,
          email: props?.userDetails?.fetchUser?.email,
          mobile: props?.userDetails?.fetchUser?.mobile?.toString(),
          whatsapp_no: props?.userDetails?.fetchUser?.whatsapp_no?.toString(),
          bank_name: props?.userDetails?.fetchUser?.bank_name,
          branch_name: props?.userDetails?.fetchUser?.branch_name,
          account_no: props?.userDetails?.fetchUser?.account_no,
          ifsc_code: props?.userDetails?.fetchUser?.ifsc_code,
          upi_id: props?.userDetails?.fetchUser?.upi_id,
          nominee_name: props?.userDetails?.fetchUser?.nominee_name,
          relative: props?.userDetails?.fetchUser?.relative,
          nomineeNumber: props?.userDetails?.fetchUser?.nomineeNumber,

          nominee_dob: moment(
            new Date(props?.userDetails?.fetchUser?.nominee_dob)?.toLocaleDateString(
              'en-GB'
            ),
            'DD-MM-YYYY'
          ),

          self_registered_vehicle: props?.userDetails?.fetchUser?.self_registered_vehicle,
          vehicle_type: props?.userDetails?.fetchUser?.vehicle_type,
          rto_number: props?.userDetails?.fetchUser?.rto_number,
          own_property: props?.userDetails?.fetchUser?.own_property?.split(',') || [],
          existing_loan: props?.userDetails?.fetchUser?.existing_loan,

          insurance:
            (props?.userDetails?.fetchUser?.insurance &&
              props?.userDetails?.fetchUser?.insurance?.split(',')) ||
            [],

          primary_health:
            (props?.userDetails?.fetchUser?.primary_health &&
              props?.userDetails?.fetchUser?.primary_health?.split(',')) ||
            [],
        })

        // Second Form
        form1?.setFieldsValue({
          pan_card_number: props?.userDetails?.fetchUser?.pan_card_number?.toString(),
          aadharcard_number: props?.userDetails?.fetchUser?.aadharcard_number?.toString(),
          // transaction_number:
          //   props?.userDetails?.fetchUser?.transaction_number?.toString(),
          nominee_aadharcard_number:
            props?.userDetails?.fetchUser?.nominee_aadharcard_number?.toString(),

          aadharcard_front_image: setAadharCardFront(
            props?.userDetails?.fetchUser?.aadharcard_front_image
          ),

          aadharcard_back_image: setAadharCardBack(
            props?.userDetails?.fetchUser?.aadharcard_back_image
          ),
          pan_card_front_image: setPanCardFront(
            props?.userDetails?.fetchUser?.pan_card_front_image
          ),
          nominee_aadharcard_front_image: setNomineeAadharFront(
            props?.userDetails?.fetchUser?.nominee_aadharcard_front_image
          ),
          nominee_aadharcard_back_image: setNomineeAadharBack(
            props?.userDetails?.fetchUser?.nominee_aadharcard_back_image
          ),
          bank_details_proof_image: setBankDetails(
            props?.userDetails?.fetchUser?.bank_details_proof_image
          ),
          signature_image: setSignature(props?.userDetails?.fetchUser?.signature_image),
        })

        // Last Step Form
        // form3?.setFieldsValue({
        // transaction_number:props?.userDetails?.fetchUser?.transaction_number?.toString(),
        // transaction_receipt_image: setTransactionReceiptDetails( props?.userDetails?.fetchUser?.transaction_receipt_image),
        // paidAmount: props?.userDetails?.fetchUser?.paidAmount?.toString(),
        // payment: props?.userDetails?.fetchUser?.payment?.toString(),
        // })
      } else {
        navigate('/dashboard')
        return
      }
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(GetSettingData())
    // eslint-disable-next-line
  }, [])

  // Life cycle methods
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    dispatch(getStateList())
    /*
    const isUserAvail = localStorage.getItem('userId')
    const isTokenAvail = localStorage.getItem('token')
    if (isUserAvail && isTokenAvail) {
      navigate('/dashboard')
      return
    }
    */
    const ipV4Address = await publicIp.v4()
    setIpAddress(ipV4Address)

    // ADD CONDIITON IF EDIT OR INSERT
    if (!UserData) {
      if (!moment(new Date()).isAfter(props?.userDetails?.expDate)) {
        form.setFieldsValue({
          facilitator: 'gramhaat',
          state: 'GUJARAT',
          self_registered_vehicle: 'YES',
          application_date: moment(),
          prefix: 'MR.',
          existing_loan: 'YES',
        })
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setStateLists(props?.allStat)
  }, [props.allStat])

  useEffect(() => {
    if (
      isCaptchaVerify &&
      isSubmitBtnPressed &&
      currentStep === 4 &&
      _.has(registrationFormData, 'transaction_number') &&
      _.has(registrationFormData, 'transaction_receipt_image')
    ) {
      handleSubmitForm()
    }
    // eslint-disable-next-line
  }, [currentStep, handleSubmitForm, registrationFormData])

  useEffect(() => {
    scroll(props?.appRef)
  }, [props, currentStep])

  // SubmitFormData
  const handleFormSubmit = async (formName, info) => {
    const valueObj = info.values
    if (Object.keys(valueObj).length > 0) {
      const cleanValueObj = _.pickBy(valueObj, function (value) {
        return !(value === undefined)
      })
      if (_.has(cleanValueObj, 'prefix')) {
        cleanValueObj.name = `${cleanValueObj.prefix}${valueObj.name}`
        delete cleanValueObj.prefix
      }
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1)
      }
      setRegistrationFormData({ ...registrationFormData, ...cleanValueObj })
      if (currentStep === 4) {
        setIsSubmitBtnPressed(true)
      }
    }
  }

  const verifyCaptcha = async token => {
    const params = {
      token: token,
    }
    verifyCaptchaApi(params).then(async data => {
      if (data && data.status === 200) {
        await setIsCaptchaVerify(true)
      } else {
        captcha.reset()
      }
    })
  }

  function handleChangeState(value, key) {
    setStateTwoLetter(key.key)
    SetTwoLetterStateNumber(key.code)
  }
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  function scroll(ref) {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  function handleStepBack() {
    form3.setFieldsValue({
      reCAPTCHA: null,
    })

    setIsSubmitBtnPressed(false)
    setIsCaptchaVerify(false)
    setCurrentStep(currentStep - 1)
  }

  async function handleSubmitForm() {
    const formData = new FormData()

    formData.append('ip', ipAddress)
    formData.append('application_date', moment())
    formData.append(
      'self_registered_vehicle',
      registrationFormData?.self_registered_vehicle
    )
    formData.append('blood_group', registrationFormData?.blood_group)
    formData.append('own_property', registrationFormData?.own_property)
    if (!registrationFormData?.own_property.includes('NONE')) {
      formData.append('existing_loan', registrationFormData?.existing_loan)
    }
    if (registrationFormData?.insurance) {
      formData.append('insurance', registrationFormData?.insurance || '')
    }

    if (registrationFormData?.vehicle_type) {
      formData.append('vehicle_type', registrationFormData?.vehicle_type || '')
    }

    if (registrationFormData?.rto_number) {
      formData.append('rto_number', registrationFormData?.rto_number || '')
    }

    if (registrationFormData?.upi_id) {
      formData.append('upi_id', registrationFormData?.upi_id || '')
    }

    formData.append('facilitator', registrationFormData?.facilitator)
    if (!hideFacilitator) {
      formData.append('facilitator_name', registrationFormData?.facilitator_name)
      formData.append(
        'facilitator_code',
        stateTwoLetter.concat('-' + registrationFormData?.facilitator_code)
      )
    }

    if (!hideAgent) {
      formData.append('agent_name', registrationFormData?.agent_name)
      formData.append('agent_code', 'GS-' + registrationFormData?.agent_code)
    }

    formData.append('total_family_members', registrationFormData?.total_family_members)

    if (registrationFormData?.yojana_number) {
      formData.append('yojana_number', registrationFormData?.yojana_number || '')
    }

    formData.append('name', registrationFormData?.name)
    formData.append('email', registrationFormData?.email)
    formData.append('mobile', registrationFormData?.mobile)

    if (registrationFormData?.nomineeNumber) {
      formData.append('nomineeNumber', registrationFormData?.nomineeNumber || '')
    }

    formData.append('dob', moment(registrationFormData?.dob).format('YYYY-MM-DD'))
    formData.append('gender', registrationFormData?.gender)
    formData.append('address', registrationFormData?.address)
    formData.append('city', registrationFormData?.city)
    formData.append('state', registrationFormData?.state)
    formData.append('pin_code', registrationFormData?.pin_code)
    formData.append('country', registrationFormData?.country)
    formData.append('weight', registrationFormData?.weight)
    formData.append('height', registrationFormData?.height)
    formData.append('business', registrationFormData?.business)
    formData.append('monthly_income', registrationFormData?.monthly_income)
    formData.append('relative', registrationFormData?.relative)
    formData.append(
      'nominee_dob',
      moment(registrationFormData?.nominee_dob).format('YYYY-MM-DD')
    )
    formData.append('nominee_name', registrationFormData?.nominee_name)
    formData.append('whatsapp_no', registrationFormData?.whatsapp_no)
    formData.append('taluka', registrationFormData?.taluka)
    formData.append('district', registrationFormData?.district)
    formData.append('marital_status', registrationFormData?.marital_status)
    formData.append('disease', registrationFormData?.disease || '')
    formData.append('primary_health', registrationFormData?.primary_health)
    formData.append('ifsc_code', registrationFormData?.ifsc_code)
    formData.append('account_no', registrationFormData?.account_no)
    formData.append('bank_name', registrationFormData?.bank_name)
    formData.append('branch_name', registrationFormData?.branch_name)
    formData.append('aadharcard_number', registrationFormData?.aadharcard_number)

    if (registrationFormData?.pan_card_number) {
      formData.append('pan_card_number', registrationFormData?.pan_card_number || null)
    }

    formData.append(
      'nominee_aadharcard_number',
      registrationFormData.nominee_aadharcard_number
    )
    formData.append('aadharcard_front_image', aadharCardFrontImageData)
    formData.append('aadharcard_back_image', aadharCardBackImageData)
    if (panCardFrontImageData) {
      formData.append('pan_card_front_image', panCardFrontImageData)
    }
    formData.append('nominee_aadharcard_front_image', nomineeAadharFrontImageData)
    formData.append('nominee_aadharcard_back_image', nomineeAadharBackImageData)
    formData.append('bank_details_proof_image', bankDetailsImageData)
    formData.append('signature_image', signatureImageData)
    formData.append('transaction_number', registrationFormData.transaction_number)
    formData.append('transaction_receipt_image', transactionReceiptImageData)
    formData.append('paidAmount', registrationFormData?.paidAmount)
    formData.append('payment', registrationFormData?.payment)

    formData.append(
      'application_file_name',
      localStorage.getItem('application_file_name')
    )

    if (UserData) {
      if (moment(new Date()).isAfter(props?.userDetails?.expDate)) {
        formData.append('userId', UserData)
        // formData.append('status', 'ACTIVE')
      }
    }

    setIsSubmitBtnPressed(false)
    if (UserData) {
      if (moment(new Date()).isAfter(props?.userDetails?.expDate)) {
        await dispatch(
          userRenewAction({
            formData,
            navigate,
            aadharcard_number: registrationFormData.aadharcard_number,

            //   const UserData = localStorage.getItem('userId')

            TwoLetterStateNumber,
            stateTwoLetter,
          })
        )
      }
    } else {
      await dispatch(
        userRegistrationAction({
          formData,
          navigate,
          aadharcard_number: registrationFormData.aadharcard_number,

          TwoLetterStateNumber,
          stateTwoLetter,
        })
      )
    }

    // await dispatch(
    //   userRegistrationAction({
    //     formData,
    //     navigate,
    //     aadharcard_number: registrationFormData.aadharcard_number,

    //     TwoLetterStateNumber,
    //     stateTwoLetter,
    //   })
    // )
  }

  const onChangesFormValues = item => {
    // INSURANCE
    if (_.has(item, 'insurance') && item?.insurance?.length === 0) {
      setisDisableAllInsurance(false)
      setisDisableNoneInsurance(false)
      setisDisableAllInsurance(false)
    }

    if (_.has(item, 'insurance') && item?.insurance?.length > 0) {
      if (item?.insurance?.includes('NONE OF ABOVE')) {
        setisDisableOptionInsurance(true)
        setisDisableNoneInsurance(false)
        setisDisableAllInsurance(true)
      }
      if (item?.insurance?.includes('ALL OF ABOVE')) {
        setisDisableOptionInsurance(true)
        setisDisableAllInsurance(false)
        setisDisableNoneInsurance(true)
      }
      if (
        !item?.insurance?.includes('NONE OF ABOVE') &&
        !item?.insurance?.includes('ALL OF ABOVE')
      ) {
        setisDisableOptionInsurance(false)
        setisDisableAllInsurance(true)
        setisDisableNoneInsurance(true)
      }
    }

    // PROPERTY
    if (_.has(item, 'own_property') && item?.own_property?.length === 0) {
      setIsDisabledAllOpt(false)
      setDisabledNoneOpt(false)
    }

    if (_.has(item, 'own_property') && item?.own_property?.length > 0) {
      if (item?.own_property?.includes('NONE')) {
        setDisabledOwnPropertyOpt(true)
        setIsDisabledAllOpt(true)
        setDisabledNoneOpt(false)
        setIsShowLoanField(false)
      }
      if (item?.own_property?.includes('ALL')) {
        setDisabledOwnPropertyOpt(true)
        setIsDisabledAllOpt(false)
        setDisabledNoneOpt(true)
        setIsShowLoanField(true)
      }
      if (!item?.own_property?.includes('NONE') && !item?.own_property?.includes('ALL')) {
        setDisabledOwnPropertyOpt(false)
        setIsDisabledAllOpt(true)
        setDisabledNoneOpt(true)
        setIsShowLoanField(true)
      }
    }

    // console.log(_.has(item, 'facilitator'), 'HAS')
    if (_.has(item, 'facilitator')) {
      if (item?.facilitator === 'direct') {
        setHideFacilitator(true)
        setFacilitatorValidation(false)
        setHideAgent(true)
        setAgentValidation(false)
      } else if (item?.facilitator === 'agent') {
        setHideFacilitator(true)
        setFacilitatorValidation(false)
        setHideAgent(false)
        setAgentValidation(true)
      } else {
        // gramm hatt
        setHideFacilitator(false)
        setFacilitatorValidation(true)
        setHideAgent(true)
        setAgentValidation(false)
      }
    }

    if (_.has(item, 'self_registered_vehicle')) {
      if (item?.self_registered_vehicle === 'YES') {
        setHideVehicle(false)
      } else {
        setHideVehicle(true)
      }
    }

    if (_.has(item, 'primary_health')) {
      if (_.has(item, 'primary_health') && item?.primary_health?.length > 0) {
        if (item?.primary_health?.includes('NONE OF ABOVE')) {
          setisDisabledHealth(true) // Other Options
          setisDisabledHealth_None(false) // NONE
        }
        if (!item?.primary_health?.includes('NONE OF ABOVE')) {
          setisDisabledHealth(false) // Other Options
          setisDisabledHealth_None(true) //  NONE
        }
      } else {
        setisDisabledHealth_None(false) // NONE
      }
    }
  }

  const onClickPdfFile = (fileUrl, isPdf) => {
    if (!isPdf) {
      return
    }
    if (fileUrl) {
      window.open(fileUrl)
    }
  }
  const onErrorInCaptcha = async () => {
    setIsCaptchaVerify(false)
  }

  const onVerifyCaptchaCallback = response => {
    verifyCaptcha(response)
  }

  // Render methods
  const renderStep1Form = () => {
    const prefixSelector = (
      <Form.Item name="prefix" noStyle>
        <Select style={{ width: 70 }}>
          <Option value="MR.">MR.</Option>
          <Option value="MRS.">MRS.</Option>
          <Option value="MISS.">MISS.</Option>
        </Select>
      </Form.Item>
    )

    // form [ Step 1 ]
    return (
      <Form
        scrollToFirstError={true}
        layout="vertical"
        form={form}
        name="registrationFormStep1"
        autoComplete="off"
        ref={formRef}
        onValuesChange={onChangesFormValues}
      >
        <Form.Item name="application_date" hidden>
          <Input />
        </Form.Item>

        <Row className="mt-2">
          <Col span={24}>
            <Title level={4}>Facilitator information</Title>
          </Col>
        </Row>
        <Col
          xl={12}
          lg={12}
          md={24}
          sm={24}
          xs={24}
          className="columnClass"
          style={{ paddingLeft: 'unset' }}
        >
          <Form.Item
            label="Facilitator"
            name="facilitator"
            rules={[
              {
                required: true,
                message: 'Facilitator information is required',
              },
            ]}
          >
            <Select align="left" placeholder="Facilitator">
              <Select.Option value="gramhaat">Gram-Haat</Select.Option>
              <Select.Option value="direct">Direct</Select.Option>
              <Select.Option value="agent">Agent</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {!hideFacilitator && (
          <Row gutter={10}>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="facilitator_name"
                label="Facilitator name"
                rules={[
                  {
                    required: facilitatorValidation,
                    message: 'Facilitator name is required',
                  },
                ]}
              >
                <Input
                  className="form-input-border"
                  autoComplete="off"
                  placeholder="Facilitator name"
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="facilitator_code"
                label="Facilitator code"
                className="field-with-prefix"
                type="number"
                rules={[
                  {
                    required: facilitatorValidation,
                    message: 'Facilitator code is required',
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: 'Only numbers are allowed',
                  },
                ]}
              >
                <Input addonBefore={stateTwoLetter} placeholder="Facilitator code" />
              </Form.Item>
            </Col>
          </Row>
        )}

        {!hideAgent && (
          <Row gutter={10}>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="agent_name"
                label="Agent name"
                rules={[
                  {
                    required: AgentValidation,
                    message: 'Agent name is required',
                  },
                ]}
              >
                <Input
                  className="form-input-border"
                  autoComplete="off"
                  placeholder="Agent name"
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="agent_code"
                label="Agent code"
                className="field-with-prefix"
                type="number"
                rules={[
                  {
                    required: AgentValidation,
                    message: 'Agent code is required',
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: 'Only numbers are allowed',
                  },
                ]}
              >
                <Input addonBefore="GS" placeholder="Agent code" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row className="mt-1">
          <Col span={24}>
            <Title level={4}>Proposer information</Title>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="name"
              label="Full name"
              className="field-with-prefix"
              rules={[
                {
                  required: true,
                  message: 'Full name is required',
                },
                {
                  pattern: /^([^0-9]*)$/,
                  message: 'Please Insert Valid Full name',
                },
              ]}
            >
              <Input
                addonBefore={prefixSelector}
                autoComplete="off"
                placeholder="Full name as per aadhaar card"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="dob"
              label="Date of birth"
              rules={[
                {
                  required: true,
                  message: 'Date of birth is required',
                },
              ]}
            >
              <DatePicker
                dropdownClassName="notranslate"
                disabledDate={d => !d || d.isAfter(new Date() + 1)}
                maxDate={moment().toDate()}
                placeholder="Date of birth"
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
                className="form-input-border"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: true,
                  message: 'Gender is required',
                },
                {
                  validator: (_, value) => {
                    if (value == 'MALE' && form.getFieldValue('prefix') == 'MR.') {
                      return Promise.resolve()
                    } else if (
                      (value == 'FEMALE' && form.getFieldValue('prefix') == 'MRS.') ||
                      (value == 'FEMALE' && form.getFieldValue('prefix') == 'MISS.')
                    ) {
                      return Promise.resolve()
                    } else if (
                      (value == 'TRANSGENDER' && form.getFieldValue('prefix') == 'MR.') ||
                      (value == 'TRANSGENDER' &&
                        form.getFieldValue('prefix') == 'MRS.') ||
                      (value == 'TRANSGENDER' && form.getFieldValue('prefix') == 'MISS.')
                    ) {
                      return Promise.resolve()
                    } else {
                      if (value) {
                        return Promise.reject('Please select valid gender')
                      } else {
                        return Promise.reject('')
                      }
                    }
                  },
                },
              ]}
            >
              <Select align="left" placeholder="Gender">
                <Select.Option value="MALE">MALE</Select.Option>
                <Select.Option value="FEMALE">FEMALE</Select.Option>
                <Select.Option value="TRANSGENDER">TRANSGENDER</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="marital_status"
              label="Marital status"
              rules={[
                {
                  required: true,
                  message: 'Marital status is required',
                },
              ]}
            >
              <Select align="left" placeholder="Marital status">
                <Option value="MARRIED">MARRIED</Option>
                <Option value="UNMARRIED">UNMARRIED</Option>
                <Option value="DIVORCED">DIVORCED</Option>
                <Option value="WIDOWED">WIDOWED</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="business"
              label="Occupation"
              rules={[
                {
                  required: true,
                  message: 'Occupation is required',
                },
              ]}
            >
              <Input
                placeholder="Occupation"
                className="form-input-border"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="monthly_income"
              label="Net monthly income"
              rules={[
                {
                  required: true,
                  message: 'Net monthly income is required',
                },
              ]}
            >
              <Select align="left" placeholder="Net monthly income">
                <Select.Option value="BELOW 5000">BELOW &#x20B9;5000</Select.Option>
                <Select.Option value="5000 TO 10000">
                  &#x20B9;5000 TO &#x20B9;10000
                </Select.Option>
                <Select.Option value="10000 & ABOVE">
                  &#x20B9;10000 &amp; ABOVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="height"
              label="Height (inch)"
              rules={[
                {
                  required: true,
                  message: 'Height is required',
                },
              ]}
            >
              <Input
                className="form-input-border"
                autoComplete="off"
                placeholder="Height (inch)"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="weight"
              label="Weight (Kg)"
              rules={[
                {
                  required: true,
                  message: 'Weight is required',
                },
              ]}
            >
              <Input
                onWheelCapture={disableScroll}
                type="number"
                placeholder="Weight (Kg)"
                className="form-input-border"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Blood group"
              name="blood_group"
              rules={[
                {
                  required: true,
                  message: 'Blood group is required',
                },
              ]}
            >
              <Select align="left" placeholder="Blood group">
                <Option value="NOT KNOWN">NOT KNOWN</Option>
                <Option value="A+">A+</Option>
                <Option value="A-">A-</Option>
                <Option value="B+">B+</Option>
                <Option value="B-">B-</Option>
                <Option value="O+">O+</Option>
                <Option value="O-">O-</Option>
                <Option value="AB+">AB+</Option>
                <Option value="AB-">AB-</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="disease" label="Pre disease (if any)">
              <Input
                className="form-input-border"
                autoComplete="off"
                placeholder="Pre disease (if any)"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Primary health"
              name="primary_health"
              rules={[
                {
                  required: true,
                  message: 'Primary health is required',
                },
              ]}
            >
              <Select
                className="tags_class"
                align="left"
                placeholder="Primary health"
                mode="multiple"
                allowClear
                onClear={() => {
                  setisDisabledHealth(false)
                }}
                onDeselect={() => {
                  setisDisabledHealth(false)
                }}
              >
                <Option value="BLOOD PRESSURE" disabled={isDisabledHealth}>
                  BLOOD PRESSURE
                </Option>
                <Option value="SUGAR" disabled={isDisabledHealth}>
                  SUGAR
                </Option>
                <Option value="HEART DISEASE" disabled={isDisabledHealth}>
                  HEART DISEASE
                </Option>
                <Option value="NONE OF ABOVE" disabled={isDisabledHealth_None}>
                  NONE OF ABOVE
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="total_family_members"
              label="Total family members"
              rules={[
                {
                  required: true,
                  message: 'Total family members is required',
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: 'family members is Not Valid',
                },
              ]}
            >
              <Input
                onWheelCapture={disableScroll}
                type="number"
                className="form-input-border"
                autoComplete="off"
                placeholder="Total family members"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="yojana_number" label="MA Yojana number">
              <Input
                className="form-input-border"
                autoComplete="off"
                placeholder="MA Yojana number"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4}>Proposer contact details</Title>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Address is required',
                },
              ]}
            >
              <TextArea
                rows={3}
                placeholder="Full address as per applicant aadhaar card"
                maxLength={250}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="city"
              label="City / Town / Village"
              rules={[
                {
                  required: true,
                  message: 'City / Town / Village is required',
                },
              ]}
            >
              <Input
                placeholder="City / Town / Village "
                className="form-input-border"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="taluka"
              label="Taluka"
              rules={[
                {
                  required: true,
                  message: 'Taluka is required',
                },
              ]}
            >
              <Input
                placeholder="Taluka"
                className="form-input-border"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="district"
              label="District"
              rules={[
                {
                  required: true,
                  message: 'District is required',
                },
              ]}
            >
              <Input
                placeholder="District"
                className="form-input-border"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: 'State is required',
                },
              ]}
            >
              <Select align="left" placeholder="State" onChange={handleChangeState}>
                {stateLists && stateLists.length > 0
                  ? stateLists.map(state => {
                      return (
                        <Option key={state.key} value={state.value} code={state.code}>
                          {state.value}
                        </Option>
                      )
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: 'Country is required',
                },
              ]}
            >
              <Input
                placeholder="Country"
                className="form-input-border"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="pin_code"
              label="Pin code"
              rules={[
                {
                  required: true,
                  message: 'Pin code is required',
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: 'Please Insert Valid Pin code',
                },
              ]}
            >
              <Input
                onWheelCapture={disableScroll}
                type="number"
                className="form-input-border"
                autoComplete="off"
                placeholder="Pin code"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
                {
                  type: 'email',
                  message: 'Please enter valid email',
                },
              ]}
            >
              <Input
                className="form-input-border"
                placeholder="Email"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="mobile"
              label="Contact number"
              rules={[
                {
                  required: true,
                  message: 'Contact number is required',
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: 'Value should contain just number',
                },
                {
                  max: 10,
                  min: 10,
                  message: 'Please check Contact number.',
                },
              ]}
            >
              <Input
                onWheelCapture={disableScroll}
                type="number"
                placeholder="Contact number"
                className="form-input-border"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="whatsapp_no"
              label="Whatsapp number"
              rules={[
                {
                  required: true,
                  message: 'Whatsapp number is required',
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: 'Value should contain just number',
                },
                {
                  max: 10,
                  min: 10,
                  message: 'Please check Whatsapp number.',
                },
              ]}
            >
              <Input
                onWheelCapture={disableScroll}
                type="number"
                placeholder="Whatsapp number"
                className="form-input-border"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4}>Proposer bank details (Aadhaar linked only)</Title>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="bank_name"
              label="Bank name"
              rules={[
                {
                  required: true,
                  message: 'Bank name is required',
                },
              ]}
            >
              <Input
                className="form-input-border"
                autoComplete="off"
                placeholder="Bank name"
              />
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="branch_name"
              label="Branch name"
              rules={[
                {
                  required: true,
                  message: 'Branch name is required',
                },
              ]}
            >
              <Input
                className="form-input-border"
                autoComplete="off"
                placeholder="Branch name"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="account_no"
              label="Account number"
              rules={[
                {
                  required: true,
                  message: 'Account number is required',
                },
              ]}
            >
              <Input
                onWheelCapture={disableScroll}
                type="number"
                className="form-input-border"
                autoComplete="off"
                placeholder="Account number"
              />
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="ifsc_code"
              label="IFSC Code"
              rules={[
                {
                  required: true,
                  message: 'IFSC Code is required',
                },
              ]}
            >
              <Input
                className="form-input-border"
                autoComplete="off"
                placeholder="IFSC Code"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="upi_id" label="UPI ID">
              <Input
                className="form-input-border"
                autoComplete="off"
                placeholder="UPI ID"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4}>Proposer Nominee Declaration</Title>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="nominee_name"
              label="Nominee name as per aadhaar card"
              rules={[
                {
                  required: true,
                  message: 'Nominee name is required',
                },
                {
                  pattern: /^([^0-9]*)$/,
                  message: 'Please Insert Valid Nominee name',
                },
              ]}
            >
              <Input
                className="form-input-border"
                autoComplete="off"
                placeholder="Nominee name"
              />
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="nominee_dob"
              label="Nominee date of birth"
              rules={[
                {
                  required: true,
                  message: 'Nominee date of birth  is required',
                },
              ]}
            >
              <DatePicker
                dropdownClassName="notranslate"
                className="form-input-border"
                disabledDate={d => !d || d.isAfter(new Date() + 1)}
                placeholder="Nominee date of birth "
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
              />
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="relative"
              label="Nominee relation"
              rules={[
                {
                  required: true,
                  message: 'Nominee relation is required',
                },
              ]}
            >
              <Select align="left" placeholder="Nominee relation">
                <Option value="HUSBAND">HUSBAND</Option>
                <Option value="WIFE">WIFE</Option>
                <Option value="SON">SON</Option>
                <Option value="DAUGHTER">DAUGHTER</Option>
                <Option value="FATHER">FATHER</Option>
                <Option value="MOTHER">MOTHER</Option>
                <Option value="BROTHER">BROTHER</Option>
                <Option value="SISTER">SISTER</Option>
                <Option value="GRANDFATHER">GRANDFATHER</Option>
                <Option value="GRANDMOTHER">GRANDMOTHER</Option>
                <Option value="OTHER">OTHER</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="nomineeNumber"
              label="Nominee contact number"
              rules={[
                {
                  required: true,
                  message: 'Nominee contact number is required',
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: 'Value should contain just number',
                },
                {
                  max: 10,
                  min: 10,
                  message: 'Please check nominee contact number.',
                },
              ]}
            >
              <Input
                onWheelCapture={disableScroll}
                type="number"
                placeholder="Nominee contact number"
                className="form-input-border"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4}>Other information</Title>
          </Col>
        </Row>

        <Col
          xl={12}
          lg={12}
          md={24}
          sm={24}
          xs={24}
          className="columnClass"
          style={{ paddingLeft: 'unset' }}
        >
          <Form.Item
            name="self_registered_vehicle"
            label="Self registered vehicle"
            rules={[
              {
                required: true,
                message: 'Self registered vehicle is required',
              },
            ]}
          >
            <Select align="left" placeholder="Self registered vehicle">
              <Option value="YES">YES</Option>
              <Option value="NO">NO</Option>
            </Select>
          </Form.Item>
        </Col>

        {!hideVehicle && (
          <Row gutter={10}>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="vehicle_type"
                label="Vehicle type"
                rules={[
                  {
                    required: true,
                    message: 'Vehicle type is required',
                  },
                ]}
              >
                <Input
                  className="form-input-border"
                  autoComplete="off"
                  placeholder="Vehicle type"
                />
              </Form.Item>
            </Col>

            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="rto_number"
                label="RTO number"
                rules={[
                  {
                    required: true,
                    message: 'RTO number is required',
                  },
                ]}
              >
                <Input
                  className="form-input-border"
                  autoComplete="off"
                  placeholder="RTO number"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="own_property"
              label="Own property"
              rules={[
                {
                  required: true,
                  message: 'Own property is required',
                },
              ]}
            >
              <Select
                className="tags_class"
                mode="multiple"
                allowClear
                align="left"
                placeholder="Own property"
                onClear={() => {
                  setDisabledOwnPropertyOpt(false)
                  setDisabledNoneOpt(false)
                  setIsDisabledAllOpt(false)
                }}
                onDeselect={event => {
                  if (event === 'ALL' || event === 'NONE') {
                    setDisabledOwnPropertyOpt(false)
                    setDisabledNoneOpt(false)
                    setIsDisabledAllOpt(false)
                  }
                }}
              >
                <Select.Option
                  value="RESIDENTIAL PROPERTY"
                  disabled={disabledOwnPropertyOpt}
                >
                  RESIDENTIAL PROPERTY
                </Select.Option>
                <Select.Option
                  value="COMMERCIAL PROPERTY"
                  disabled={disabledOwnPropertyOpt}
                >
                  COMMERCIAL PROPERTY
                </Select.Option>
                <Select.Option value="LAND" disabled={disabledOwnPropertyOpt}>
                  LAND
                </Select.Option>
                <Select.Option value="ALL" disabled={isDisabledAllOpt}>
                  ALL
                </Select.Option>
                <Select.Option value="NONE" disabled={isDisabledNoneOpt}>
                  NONE
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {isShowLoanField && (
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name="existing_loan"
                label="Existing loan"
                rules={[
                  {
                    required: true,
                    message: 'Existing loan is required',
                  },
                ]}
              >
                <Select align="left" placeholder="Existing loan, if any (nature of loan)">
                  <Select.Option value="YES">YES</Select.Option>
                  <Select.Option value="NO">NO</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Col
          xl={12}
          lg={12}
          md={24}
          sm={24}
          xs={24}
          className="columnClass"
          style={{ paddingLeft: 'unset' }}
        >
          <Form.Item
            name="insurance"
            label="Insurance (if any)"
            rules={[
              {
                required: true,
                message: 'Insurance is required',
              },
            ]}
          >
            <Select
              className="tags_class"
              align="left"
              placeholder="Insurance (if any)"
              mode="multiple"
              allowClear
              onClear={() => {
                setisDisableOptionInsurance(false)
                setisDisableNoneInsurance(false)
                setisDisableAllInsurance(false)
              }}
              onDeselect={event => {
                if (event === 'ALL OF ABOVE' || event === 'NONE OF ABOVE') {
                  setisDisableOptionInsurance(false)
                  setisDisableNoneInsurance(false)
                  setisDisableAllInsurance(false)
                }
              }}
            >
              <Option value="MEDICAL INSURANCE" disabled={isDisableOptionInsurance}>
                MEDICAL INSURANCE
              </Option>
              <Option value="LIFE INSURANCE" disabled={isDisableOptionInsurance}>
                LIFE INSURANCE
              </Option>
              <Option
                value="PERSONAL ACCIDENT INSURANCE"
                disabled={isDisableOptionInsurance}
              >
                PERSONAL ACCIDENT INSURANCE
              </Option>
              <Option value="NONE OF ABOVE" disabled={isDisableNoneInsurance}>
                NONE OF ABOVE
              </Option>
              <Option value="ALL OF ABOVE" disabled={isDisableAllInsurance}>
                ALL OF ABOVE
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Row className={currentStep === 1 ? '' : 'hidden'}>
          <Col span={24} className="mt-3 mb-3" style={{ textAlign: 'right' }}>
            <Button className="place-order-button w-50" htmlType="submit">
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }

  // form1 [ Step 2 ]
  const renderStep2Form = () => {
    return (
      <Form
        layout="vertical"
        name="registrationFormStep2"
        autoComplete="off"
        ref={formRef}
        onValuesChange={onChangesFormValues}
        scrollToFirstError
        form={form1}
      >
        <Row className="mt-2">
          <Col span={24}>
            <Title level={4}>Proposer Statutory Disclosure</Title>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="aadharcard_number"
              label="Aadhaar card number"
              rules={[
                {
                  required: true,
                  message: 'Aadhaar card number is required',
                },
                {
                  max: 12,
                  min: 12,
                  message: 'Aadhaar card number is not valid',
                },
              ]}
            >
              <Input
                disabled={UserData ? true : false}
                onWheelCapture={disableScroll}
                type="number"
                placeholder="Aadhaar card number"
                className="form-input-border"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="aadharcard_front_image"
              label="Aadhaar card front"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: aadharcard_front_image_validation,
                  message: 'Aadhaar card front is required',
                },
                {
                  message: ImagePdfFileAllowedMessage,
                  validator: (_, value) => {
                    if (value !== undefined) {
                      let extensionCheck = value[0]?.name?.split('.')

                      if (
                        acceptImagePdfFileType?.includes(
                          extensionCheck[extensionCheck?.length - 1]
                        )
                      ) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('Some message here')
                      }
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
              className={UserData ? 'h-10' : `formItemWithImagePreview`}
            >
              {!UserData && (
                <Upload
                  maxCount={1}
                  name="aadharcard_front_image"
                  multiple={false}
                  accept={acceptOnlyFileType}
                  customRequest={dummyRequest}
                  beforeUpload={(file, fileList) => {
                    if (file?.type && file.type === 'application/pdf') {
                      setIsPdfFileUploaded({
                        ...isPdfFileUploaded,
                        aadharcard_front_image: true,
                      })
                    } else {
                      setIsImage({
                        ...IsSetImage,
                        user_aadhaar_card_front_img: file?.type && file.type,
                      })

                      setIsPdfFileUploaded({
                        ...isPdfFileUploaded,
                        aadharcard_front_image: false,
                      })
                    }
                    setAadharCardFront(URL.createObjectURL(file))
                    setAadharCardFrontImageData(fileList[0])
                  }}
                  onRemove={file => {
                    if (file) {
                      setAadharCardFront('')
                      setAadharCardFrontImageData('')
                    }
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              )}
            </Form.Item>
            {/*
            {aadharCardFront && (
              <div
                className={`image-wrapper ${
                  isPdfFileUploaded.aadharcard_front_image ? 'pdf' : ''
                }`}
                onClick={() =>
                  onClickPdfFile(
                    aadharCardFront,
                    _.has(isPdfFileUploaded, 'aadharcard_front_image')
                  )
                }
              >
                {isPdfFileUploaded &&
                  _.has(isPdfFileUploaded, 'aadharcard_front_image') &&
                  isPdfFileUploaded.aadharcard_front_image && (
                    <img className="profImage" src={pdfLogo} alt="" />
                  )}
              </div>
            )}
            
            {aadharCardFront &&
              imageDisplayType?.includes(IsSetImage?.user_aadhaar_card_front_img) && (
                <ImageDisplayComponent ImageName={aadharCardFront} />
              )} */}

            {/* START */}
            {imageDisplayType?.includes(IsSetImage?.user_aadhaar_card_front_img) ? (
              <ImageDisplayComponent ImageName={aadharCardFront} />
            ) : (
              (aadharCardFront?.includes('.jpg') ||
                aadharCardFront?.includes('.jpeg') ||
                aadharCardFront?.includes('.png')) && (
                <ImageDisplayComponent ImageName={aadharCardFront} />
              )
            )}

            <PdfDisplayComponent
              name1={aadharCardFront}
              name2={isPdfFileUploaded?.aadharcard_front_image}
              name3={isPdfFileUploaded}
              name4={'aadharcard_front_image'}
              name5={onClickPdfFile}
            />
            {/* END */}
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="aadharcard_back_image"
              label="Aadhaar card back"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: aadharcard_back_image_validation,
                  message: 'Aadhaar card back is required',
                },
                {
                  message: ImagePdfFileAllowedMessage,
                  validator: (_, value) => {
                    if (value !== undefined) {
                      let extensionCheck = value[0]?.name?.split('.')

                      if (
                        acceptImagePdfFileType?.includes(
                          extensionCheck[extensionCheck?.length - 1]
                        )
                      ) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('Some message here')
                      }
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
              // height: 10px;
              // className="formItemWithImagePreview"
              className={UserData ? 'h-10' : `formItemWithImagePreview`}
            >
              {!UserData && (
                <Upload
                  maxCount={1}
                  name="aadharcard_back_image"
                  customRequest={dummyRequest}
                  accept={acceptOnlyFileType}
                  beforeUpload={(file, fileList) => {
                    if (file?.type && file.type === 'application/pdf') {
                      setIsPdfFileUploaded({
                        ...isPdfFileUploaded,
                        aadharcard_back_image: true,
                      })
                    } else {
                      setIsImage({
                        ...IsSetImage,
                        user_aadhaar_ard_back_img: file?.type && file.type,
                      })

                      setIsPdfFileUploaded({
                        ...isPdfFileUploaded,
                        aadharcard_back_image: false,
                      })
                    }
                    setAadharCardBack(URL.createObjectURL(file))
                    setAadharCardBackImageData(fileList[0])
                  }}
                  onRemove={file => {
                    if (file) {
                      setAadharCardBack('')
                      setAadharCardBackImageData('')
                    }
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              )}
            </Form.Item>
            {/* {aadharCardBack && (
              <div
                className={`image-wrapper ${
                  isPdfFileUploaded.aadharcard_back_image ? 'pdf' : ''
                }`}
                onClick={() =>
                  onClickPdfFile(
                    aadharCardBack,
                    _.has(isPdfFileUploaded, 'aadharcard_back_image')
                  )
                }
              >
                {isPdfFileUploaded &&
                  _.has(isPdfFileUploaded, 'aadharcard_back_image') &&
                  isPdfFileUploaded.aadharcard_back_image && (
                    <img className="profImage" src={pdfLogo} alt="" />
                  )}
              </div>
            )}
            {aadharCardBack &&
              imageDisplayType?.includes(IsSetImage?.user_aadhaar_ard_back_img) && (
                <ImageDisplayComponent ImageName={aadharCardBack} />
              )} */}

            {imageDisplayType?.includes(IsSetImage?.user_aadhaar_ard_back_img) ? (
              <ImageDisplayComponent ImageName={aadharCardBack} />
            ) : (
              (aadharCardBack?.includes('.jpg') ||
                aadharCardBack?.includes('.jpeg') ||
                aadharCardBack?.includes('.png')) && (
                <ImageDisplayComponent ImageName={aadharCardBack} />
              )
            )}

            <PdfDisplayComponent
              name1={aadharCardBack}
              name2={isPdfFileUploaded?.aadharcard_back_image}
              name3={isPdfFileUploaded}
              name4={'aadharcard_back_image'}
              name5={onClickPdfFile}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="pan_card_number"
              label="PAN card number"
              rules={[
                {
                  required: false,
                },
                {
                  max: 10,
                  min: 10,
                  message: 'PAN card number is not valid',
                },
              ]}
            >
              <Input placeholder="PAN card number" className="form-input-border" />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="PAN card front"
              getValueFromEvent={normFile}
              name="pan_card_front_image"
              rules={[
                {
                  required: false,
                },
                {
                  message: ImagePdfFileAllowedMessage,
                  validator: (_, value) => {
                    if (value !== undefined) {
                      let extensionCheck = value[0]?.name?.split('.')

                      if (
                        acceptImagePdfFileType?.includes(
                          extensionCheck[extensionCheck?.length - 1]
                        )
                      ) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('Some message here')
                      }
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
              className="formItemWithImagePreview"
            >
              <Upload
                maxCount={1}
                name="pan_card_front_image"
                accept={acceptOnlyFileType}
                customRequest={dummyRequest}
                beforeUpload={(file, fileList) => {
                  if (file?.type && file.type === 'application/pdf') {
                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      pan_card_front_image: true,
                    })
                  } else {
                    setIsImage({
                      ...IsSetImage,
                      user_pan_card_img: file?.type && file.type,
                    })

                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      pan_card_front_image: false,
                    })
                  }
                  setPanCardFront(URL.createObjectURL(file))
                  setPanCardFrontImageData(fileList[0])
                }}
                onRemove={file => {
                  if (file) {
                    setPanCardFront('')
                    setPanCardFrontImageData('')
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            {/* {panCardFront && (
              <div
                className={`image-wrapper ${
                  isPdfFileUploaded.pan_card_front_image ? 'pdf' : ''
                }`}
                onClick={() =>
                  onClickPdfFile(
                    panCardFront,
                    _.has(isPdfFileUploaded, 'pan_card_front_image')
                  )
                }
              >
                {isPdfFileUploaded &&
                  _.has(isPdfFileUploaded, 'pan_card_front_image') &&
                  isPdfFileUploaded.pan_card_front_image && (
                    <img className="profImage" src={pdfLogo} alt="" />
                  )}
              </div>
            )}
            {panCardFront &&
              imageDisplayType?.includes(IsSetImage?.user_pan_card_img) && (
                <ImageDisplayComponent ImageName={panCardFront} />
              )} */}

            {panCardFront && imageDisplayType?.includes(IsSetImage?.user_pan_card_img) ? (
              <ImageDisplayComponent ImageName={panCardFront} />
            ) : (
              (panCardFront?.includes('.jpg') ||
                panCardFront?.includes('.jpeg') ||
                panCardFront?.includes('.png')) && (
                <ImageDisplayComponent ImageName={panCardFront} />
              )
            )}

            <PdfDisplayComponent
              name1={panCardFront}
              name2={isPdfFileUploaded?.pan_card_front_image}
              name3={isPdfFileUploaded}
              name4={'pan_card_front_image'}
              name5={onClickPdfFile}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col span={24}>
            <Title level={4}>Proposer nominee disclosure</Title>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="nominee_aadharcard_number"
              label="Nominee aadhaar card"
              rules={[
                {
                  required: true,
                  message: 'Nominee aadhaar card is required',
                },
                {
                  max: 12,
                  min: 12,
                  message: 'Please check nominee aadhaar card no',
                },
              ]}
            >
              <Input
                onWheelCapture={disableScroll}
                type="number"
                placeholder="Nominee aadhaar card number"
                className="form-input-border"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Nominee aadhaar card front"
              name="nominee_aadharcard_front_image"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: nominee_aadharcard_front_image_validation,
                  message: 'Nominee aadhaar card front is required',
                },
                {
                  message: ImagePdfFileAllowedMessage,
                  validator: (_, value) => {
                    if (value !== undefined) {
                      let extensionCheck = value[0]?.name?.split('.')

                      if (
                        acceptImagePdfFileType?.includes(
                          extensionCheck[extensionCheck?.length - 1]
                        )
                      ) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('Some message here')
                      }
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
              className="formItemWithImagePreview"
            >
              <Upload
                maxCount={1}
                name="nominee_aadharcard_front_image"
                customRequest={dummyRequest}
                accept={acceptOnlyFileType}
                beforeUpload={(file, fileList) => {
                  if (file?.type && file.type === 'application/pdf') {
                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      nominee_aadharcard_front_image: true,
                    })
                  } else {
                    setIsImage({
                      ...IsSetImage,
                      nominee_aadhar_card_front_img: file?.type && file.type,
                    })
                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      nominee_aadharcard_front_image: false,
                    })
                  }
                  setNomineeAadharFront(URL.createObjectURL(file))
                  setNomineeAadharFrontImageData(fileList[0])
                }}
                onRemove={file => {
                  if (file) {
                    setNomineeAadharFront('')
                    setNomineeAadharFrontImageData('')
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            {/* {nomineeAadharFront && (
              <div
                className={`image-wrapper ${
                  isPdfFileUploaded.nominee_aadharcard_front_image ? 'pdf' : ''
                }`}
                onClick={() =>
                  onClickPdfFile(
                    nomineeAadharFront,
                    _.has(isPdfFileUploaded, 'nominee_aadharcard_front_image')
                  )
                }
              >
                {isPdfFileUploaded &&
                  _.has(isPdfFileUploaded, 'nominee_aadharcard_front_image') &&
                  isPdfFileUploaded.nominee_aadharcard_front_image && (
                    <img className="profImage" src={pdfLogo} alt="" />
                  )}
              </div>
            )}
            {nomineeAadharFront &&
              imageDisplayType?.includes(IsSetImage?.nominee_aadhar_card_front_img) && (
                <ImageDisplayComponent ImageName={nomineeAadharFront} />
              )} */}

            {imageDisplayType?.includes(IsSetImage?.nominee_aadhar_card_front_img) ? (
              <ImageDisplayComponent ImageName={nomineeAadharFront} />
            ) : (
              (nomineeAadharFront?.includes('.jpg') ||
                nomineeAadharFront?.includes('.jpeg') ||
                nomineeAadharFront?.includes('.png')) && (
                <ImageDisplayComponent ImageName={nomineeAadharFront} />
              )
            )}

            <PdfDisplayComponent
              name1={nomineeAadharFront}
              name2={isPdfFileUploaded?.nominee_aadharcard_front_image}
              name3={isPdfFileUploaded}
              name4={'nominee_aadharcard_front_image'}
              name5={onClickPdfFile}
            />
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Nominee aadhaar card back"
              getValueFromEvent={normFile}
              name="nominee_aadharcard_back_image"
              rules={[
                {
                  required: nominee_aadharcard_back_image_validation,
                  message: 'Nominee aadhaar card back is required',
                },
                {
                  message: ImagePdfFileAllowedMessage,
                  validator: (_, value) => {
                    if (value !== undefined) {
                      let extensionCheck = value[0]?.name?.split('.')

                      if (
                        acceptImagePdfFileType?.includes(
                          extensionCheck[extensionCheck?.length - 1]
                        )
                      ) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('Some message here')
                      }
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
              className="formItemWithImagePreview"
            >
              <Upload
                maxCount={1}
                name="nominee_aadharcard_back_image"
                customRequest={dummyRequest}
                accept={acceptOnlyFileType}
                beforeUpload={(file, fileList) => {
                  if (file?.type && file.type === 'application/pdf') {
                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      nominee_aadharcard_back_image: true,
                    })
                  } else {
                    setIsImage({
                      ...IsSetImage,
                      nominee_aadhar_card_back_img: file?.type && file.type,
                    })

                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      nominee_aadharcard_back_image: false,
                    })
                  }
                  setNomineeAadharBack(URL.createObjectURL(file))
                  setNomineeAadharBackImageData(fileList[0])
                }}
                onRemove={file => {
                  if (file) {
                    setNomineeAadharBack('')
                    setNomineeAadharBackImageData('')
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            {/* {nomineeAadharBack && (
              <div
                className={`image-wrapper ${
                  isPdfFileUploaded.nominee_aadharcard_back_image ? 'pdf' : ''
                }`}
                onClick={() =>
                  onClickPdfFile(
                    nomineeAadharBack,
                    _.has(isPdfFileUploaded, 'nominee_aadharcard_back_image')
                  )
                }
              >
                {isPdfFileUploaded &&
                  _.has(isPdfFileUploaded, 'nominee_aadharcard_back_image') &&
                  isPdfFileUploaded.nominee_aadharcard_back_image && (
                    <img className="profImage" src={pdfLogo} alt="" />
                  )}
              </div>
            )}
            {nomineeAadharBack &&
              imageDisplayType?.includes(IsSetImage?.nominee_aadhar_card_back_img) && (
                <ImageDisplayComponent
                 ImageName={nomineeAadharBack} />
              )} */}

            {imageDisplayType?.includes(IsSetImage?.nominee_aadhar_card_back_img) ? (
              <ImageDisplayComponent ImageName={nomineeAadharBack} />
            ) : (
              (nomineeAadharBack?.includes('.jpg') ||
                nomineeAadharBack?.includes('.jpeg') ||
                nomineeAadharBack?.includes('.png')) && (
                <ImageDisplayComponent ImageName={nomineeAadharBack} />
              )
            )}

            <PdfDisplayComponent
              name1={nomineeAadharBack}
              name2={isPdfFileUploaded?.nominee_aadharcard_back_image}
              name3={isPdfFileUploaded}
              name4={'nominee_aadharcard_back_image'}
              name5={onClickPdfFile}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col span={24}>
            <Title level={4}>Bank Information </Title>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="bank_details_proof_image"
              label="Passbook/cancelled cheque"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: bank_details_proof_image_validation,
                  message: 'Passbook/cancelled cheque is required',
                },
                {
                  message: ImagePdfFileAllowedMessage,
                  validator: (_, value) => {
                    if (value !== undefined) {
                      let extensionCheck = value[0]?.name?.split('.')

                      if (
                        acceptImagePdfFileType?.includes(
                          extensionCheck[extensionCheck?.length - 1]
                        )
                      ) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('Some message here')
                      }
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
              className="formItemWithImagePreview"
            >
              <Upload
                maxCount={1}
                name="bank_details_proof_image"
                accept={acceptOnlyFileType}
                customRequest={dummyRequest}
                beforeUpload={(file, fileList) => {
                  if (file?.type && file.type === 'application/pdf') {
                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      bank_details_proof_image: true,
                    })
                  } else {
                    setIsImage({
                      ...IsSetImage,
                      passbook_cancel_cheque_img: file?.type && file.type,
                    })

                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      bank_details_proof_image: false,
                    })
                  }
                  setBankDetails(URL.createObjectURL(file))
                  setBankDetailsImageData(fileList[0])
                }}
                onRemove={file => {
                  if (file) {
                    setBankDetails('')
                    setBankDetailsImageData('')
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            {/* {bankDetails && (
              <div
                className={`image-wrapper ${
                  isPdfFileUploaded.bank_details_proof_image ? 'pdf' : ''
                }`}
                onClick={() =>
                  onClickPdfFile(
                    bankDetails,
                    _.has(isPdfFileUploaded, 'bank_details_proof_image')
                  )
                }
              >
                {isPdfFileUploaded &&
                  _.has(isPdfFileUploaded, 'bank_details_proof_image') &&
                  isPdfFileUploaded.bank_details_proof_image && (
                    <img className="profImage" src={pdfLogo} alt="" />
                  )}
              </div>
            )}
            {bankDetails &&
              imageDisplayType?.includes(IsSetImage?.passbook_cancel_cheque_img) && (
                <ImageDisplayComponent ImageName={bankDetails} />
              )} */}

            {imageDisplayType?.includes(IsSetImage?.passbook_cancel_cheque_img) ? (
              <ImageDisplayComponent ImageName={bankDetails} />
            ) : (
              (bankDetails?.includes('.jpg') ||
                bankDetails?.includes('.jpeg') ||
                bankDetails?.includes('.png')) && (
                <ImageDisplayComponent ImageName={bankDetails} />
              )
            )}

            <PdfDisplayComponent
              name1={bankDetails}
              name2={isPdfFileUploaded?.bank_details_proof_image}
              name3={isPdfFileUploaded}
              name4={'bank_details_proof_image'}
              name5={onClickPdfFile}
            />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="signature_image"
              label="Proposer signature"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: signature_image_validation,
                  message: 'Signature is required',
                },
                {
                  message: 'Only Allowed png , jpg , jpeg  images',
                  validator: (_, value) => {
                    if (value !== undefined) {
                      let extensionCheck = value[0]?.name?.split('.')
                      if (
                        ['png', 'jpg', 'jpeg']?.includes(
                          extensionCheck[extensionCheck?.length - 1]
                        )
                      ) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('Some message here')
                      }
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
              className="formItemWithImagePreview"
            >
              <Upload
                maxCount={1}
                name="signature_image"
                accept=".png,.jpg,.jpeg"
                customRequest={dummyRequest}
                beforeUpload={(file, fileList) => {
                  if (file?.type && file.type === 'application/pdf') {
                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      signature_image: true,
                    })
                  } else {
                    setIsImage({
                      ...IsSetImage,
                      signature_img: file?.type && file.type,
                    })

                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      signature_image: false,
                    })
                  }
                  setSignature(URL.createObjectURL(file))
                  setSignatureImageData(fileList[0])
                }}
                onRemove={file => {
                  if (file) {
                    setSignature('')
                    setSignatureImageData('')
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            {/* {signature && (
              <div
                className={`image-wrapper ${
                  isPdfFileUploaded.signature_image ? 'pdf' : ''
                }`}
                onClick={() =>
                  onClickPdfFile(signature, _.has(isPdfFileUploaded, 'signature_image'))
                }
              >
                {isPdfFileUploaded &&
                  _.has(isPdfFileUploaded, 'signature_image') &&
                  isPdfFileUploaded.signature_image && (
                    <img className="profImage" src={NoImage} alt="" />
                  )}
              </div>
            )}

            {signature && imageDisplayType?.includes(IsSetImage?.signature_img) && (
              <ImageDisplayComponent ImageName={signature} />
            )} */}

            {imageDisplayType?.includes(IsSetImage?.signature_img) ? (
              <ImageDisplayComponent ImageName={signature} />
            ) : (
              (signature?.includes('.jpg') ||
                signature?.includes('.jpeg') ||
                signature?.includes('.png')) && (
                <ImageDisplayComponent ImageName={signature} />
              )
            )}

            <PdfDisplayComponent
              name1={signature}
              name2={isPdfFileUploaded?.signature_image}
              name3={isPdfFileUploaded}
              name4={'signature_image'}
              name5={onClickPdfFile}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={10}>
          <Col span={12} className="mt-3 mb-3" style={{ textAlign: 'left' }}>
            <Button
              onClick={() => handleStepBack()}
              className="place-order-button w-50 back-btn"
              htmlType="submit"
            >
              Back
            </Button>
          </Col>
          <Col span={12} className="mt-3 mb-3" style={{ textAlign: 'right' }}>
            <Button className="place-order-button w-50" htmlType="submit">
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }

  // [ Step 3 ]
  const renderStep3Form = () => {
    return (
      <Step3
        id={'idddd'}
        hideAgent={hideAgent}
        hideFacilitator={hideFacilitator}
        stateTwoLetter={stateTwoLetter}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setIsCaptchaVerify={setIsCaptchaVerify}
        form={form}
        form1={form1}
        signature={signature}
        signatureImageData={signatureImageData}
      />
    )
  }

  // form3 [ Step 4 ]
  const renderStep4Form = () => {
    return (
      <Form
        autoComplete="off"
        layout="vertical"
        ref={formRef}
        scrollToFirstError
        form={form3}
        name="registrationFormStep4"
        onValuesChange={onChangesFormValues}
      >
        <Row gutter={10} className="gig-step4-wrapper">
          <Col xxl={10} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item name="QR">
              <div style={{ margin: 'auto' }}>
                <img className="qrImage" src={settingData?.qr_code_image} alt="QR" />
              </div>
            </Form.Item>
          </Col>

          <Col xxl={14} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item className="notranslate">
              <Descriptions title="Our bank details">
                <Descriptions.Item label="">
                  {settingData?.account_name}
                  <br />
                  Account number: {settingData?.account_no} <br />
                  IFSC Code: {settingData?.ifsc_code}
                  <br /> Bank name: {settingData?.bank_name}
                  <br /> Branch code: {settingData?.branch_code}
                  <br />
                  Branch name: {settingData?.branch_name}
                  <br />
                  UPI ID: {settingData?.upi_id}
                </Descriptions.Item>
              </Descriptions>
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            justifyContent: 'center',
            color: 'red',
            fontWeight: 600,
          }}
        >
          <span className="note">
            PAY HERE {gigSureLabel} MembershipsONE Annual Subscription Fees &#x20B9;999 +
            GST = &#x20B9;1180
          </span>
        </Row>
        <Row gutter={10} className="form-item-step4">
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="transaction_number"
              label="Transaction number"
              rules={[
                {
                  required: true,
                  message: 'Transaction number is required',
                },
              ]}
            >
              <Input
                onWheelCapture={disableScroll}
                type="number"
                placeholder="Transaction number"
                className="form-input-border"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="transaction_receipt_image"
              label="Transaction receipt"
              rules={[
                {
                  required: transaction_receipt_image_validation,
                  message: 'Transaction receipt is required',
                },
                {
                  message: ImagePdfFileAllowedMessage,
                  validator: (_, value) => {
                    if (value !== undefined) {
                      let extensionCheck = value[0]?.name?.split('.')

                      if (
                        acceptImagePdfFileType?.includes(
                          extensionCheck[extensionCheck?.length - 1]
                        )
                      ) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('Some message here')
                      }
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
              getValueFromEvent={normFile}
              className="formItemWithImagePreview"
            >
              <Upload
                maxCount={1}
                name="transaction_receipt_image"
                accept={acceptOnlyFileType}
                customRequest={dummyRequest}
                beforeUpload={(file, fileList) => {
                  if (file?.type && file.type === 'application/pdf') {
                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      transaction_receipt_image: true,
                    })
                  } else {
                    setIsImage({
                      ...IsSetImage,
                      transaction_receipt_img: file?.type && file.type,
                    })

                    setIsPdfFileUploaded({
                      ...isPdfFileUploaded,
                      transaction_receipt_image: false,
                    })
                  }
                  setTransactionReceiptDetails(URL.createObjectURL(file))
                  setTransactionReceiptImageData(fileList[0])
                }}
                onRemove={file => {
                  if (file) {
                    setTransactionReceiptDetails('')
                    setTransactionReceiptImageData('')
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>

            {imageDisplayType?.includes(IsSetImage?.transaction_receipt_img) ? (
              <ImageDisplayComponent ImageName={transactionReceiptDetails} />
            ) : (
              (transactionReceiptDetails?.includes('.jpg') ||
                transactionReceiptDetails?.includes('.jpeg') ||
                transactionReceiptDetails?.includes('.png')) && (
                <ImageDisplayComponent ImageName={transactionReceiptDetails} />
              )
            )}

            <PdfDisplayComponent
              name1={transactionReceiptDetails}
              name2={isPdfFileUploaded?.transaction_receipt_image}
              name3={isPdfFileUploaded}
              name4={'transaction_receipt_image'}
              name5={onClickPdfFile}
            />

            {/* {transactionReceiptDetails && (
              <div
                className={`image-wrapper ${
                  isPdfFileUploaded.transaction_receipt_image ? 'pdf' : ''
                }`}
                onClick={() =>
                  onClickPdfFile(
                    transactionReceiptDetails,
                    _.has(isPdfFileUploaded, 'transaction_receipt_image')
                  )
                }
              >
                {isPdfFileUploaded &&
                  _.has(isPdfFileUploaded, 'transaction_receipt_image') &&
                  isPdfFileUploaded.transaction_receipt_image && (
                    <img className="profImage" src={pdfLogo} alt="" />
                  )}
              </div>
            )}
            {transactionReceiptDetails &&
              imageDisplayType?.includes(IsSetImage?.transaction_receipt_img) && (
                <ImageDisplayComponent ImageName={transactionReceiptDetails} />
              )} */}
          </Col>
        </Row>
        <Row gutter={10} className="form-item-step4">
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="paidAmount"
              label="Paid Amount"
              rules={[
                {
                  required: true,
                  message: 'Paid Amount is required',
                },
              ]}
            >
              <Input
                onWheelCapture={disableScroll}
                type="number"
                placeholder="Paid Amount"
                className="form-input-border"
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="payment"
              label="Mode of Payment"
              rules={[
                {
                  required: true,
                  message: 'Mode of Payment is required',
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Mode of Payment"
                className="form-input-border"
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            className="reCAPTCHAClass"
            name="reCAPTCHA"
            rules={[
              {
                required: true,
                message: 'reCAPTCHA is required',
              },
            ]}
          >
            <ReCAPTCHA
              size="normal"
              ref={captcha}
              sitekey={RecaptchaKey}
              onChange={onVerifyCaptchaCallback}
              onExpired={onErrorInCaptcha}
              onErrored={onErrorInCaptcha}
            />
          </Form.Item>
        </Col>
        <br />
        <Row className={currentStep === 4 ? '' : 'hidden'} gutter={10}>
          <Col span={12} className="mt-3 mb-3" style={{ textAlign: 'left' }}>
            <Button
              onClick={() => handleStepBack()}
              className="place-order-button w-50 back-btn"
            >
              Back
            </Button>
          </Col>

          <Col span={12} className="mt-3 mb-3" style={{ textAlign: 'right' }}>
            <Form.Item shouldUpdate className="submit">
              <Button
                className="place-order-button2 w-50 success-btn2"
                // type="success"
                htmlType="submit"
                loading={isLoading}
              >
                {moment(new Date()).isAfter(props?.userDetails?.expDate)
                  ? 'Renew Plan'
                  : 'Registration'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  return (
    <div className="subscription-container center-content">
      <Row gutter={2}>
        <Col
          xxl={18}
          xl={20}
          lg={20}
          md={22}
          sm={24}
          xs={24}
          style={{ margin: '0 auto' }}
        >
          <div
            style={{ padding: '30px' }}
            className="checkout-form-container gig-sure-card-wrapper"
          >
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Title level={2}>
                  Inclusive Care platform for Gig Community - {gigSureLabel}
                </Title>
              </Col>

              <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ fontSize: '14px' }}>
                <div style={{ textAlign: 'justify' }}>
                  {gigSureLabel} : An affinity Membership Program of eRenovision
                  Management (I) Pvt. Ltd powered by Gram-Haat Initiative. This Annual
                  membership is property of eRMPL issued to its {gigSureLabel} Members
                  only. This Membership is governed by {gigSureLabel} and it&#39;s
                  Associate Partner&#39;s Terms & Conditions may be amended from
                  time-to-time with prior notice. <br /> This is Non-Transferable in
                  benefit of registered member based on self- declared information by
                  Proposer intent to be the Member. All applicable benefits are as per
                  eligibility criteria in line with rule & policy of governing body /
                  government entity and / or Service provider. Member&#39;s information
                  remains strictly confidential as per privacy policy with consent of
                  Proposer as per submitted application herewith.
                </div>
                <br />
                <h3>
                  GigSURE initiative of eRenovision Management(I) Pvt.Ltd as Startup
                  (CERTIFICATE NO: DIPP104951) by DIPP - Government of India
                </h3>
              </Col>
            </Row>
            {/* START S3  */}

            <Form.Provider onFormFinish={handleFormSubmit}>
              {/* START S1 */}
              {currentStep === 1 && renderStep1Form()}

              {/* START S2 */}
              {currentStep === 2 && renderStep2Form()}

              {/* START S4 */}
              <br />
              {currentStep === 4 && renderStep4Form()}
            </Form.Provider>
            {currentStep === 3 && renderStep3Form()}
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    allStat: state?.userData?.allStats,
    userDetails: state?.userData?.userDetails,
  }
}

export default connect(mapStateToProps, { getStateList })(Registration)

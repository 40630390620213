import { Col, Row, Image } from 'antd'
import { Link } from 'react-router-dom'
import 'rc-footer/assets/index.css'
import styles from '../../screens/LandingPage/LandingPage.module.css'

const Footer = () => {
  return (
    <>
      <section className={styles.footerSection}>
        <Row className={styles.footerSectionRow}>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={24}
            className={styles.footerFirstCol}
          >
            <div>
              <Image preview={false} src="/img/GigsureLogo.png" />
              <div className={styles.contactNoText}>(+91) 9876543210</div>
              <div className={styles.addressText}>
                Lorem ipsum dolor sit amet, consectetur adipis-12345
              </div>
              <div className={styles.socialMediaDiv}>
                <div>
                  <Image src="/img/fb.png" preview={false} />
                </div>
                <div>
                  <Image src="/img/twitter.png" preview={false} />
                </div>
                <div>
                  <Image src="/img/linkedin.png" preview={false} />
                </div>
                <div>
                  <Image src="/img/insta.png" preview={false} />
                </div>
              </div>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={24}
            className={styles.footerColData1}
          >
            <div>
              <div className={styles.footerSectionHeader}>Insurance</div>
              <div style={{ marginTop: '15px' }}>
                <div className={styles.footerLinkText}>Business Insurance</div>
                <div className={styles.footerLinkText}>Life Insurance</div>
                <div className={styles.footerLinkText}>Home Insurance</div>
                <div className={styles.footerLinkText}>Fire Insurance</div>
                <div className={styles.footerLinkText}>Travel Insurance</div>
                <div className={styles.footerLinkText}>Medical Insurance</div>
              </div>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={24}
            className={styles.footerColData}
          >
            <div>
              <div className={styles.footerSectionHeader}>Links</div>
              <div style={{ marginTop: '15px' }}>
                <div className={styles.footerLinkText}>
                  <Link to="/">Home</Link>
                </div>
                <div className={styles.footerLinkText}>
                  <Link to="/#">About</Link>
                </div>
                <div className={styles.footerLinkText}>
                  <Link to="/#">Insurance</Link>
                </div>

                {!window.localStorage.getItem('token') &&
                !window.localStorage.getItem('userId') &&
                !window.localStorage.getItem('DateTimeTokenFront') ? (
                  <div className={styles.footerLinkText}>
                    <Link to="/login">Renew</Link>
                  </div>
                ) : (
                  <div className={styles.footerLinkText}>
                    <Link to="/dashboard">Dashboard</Link>
                  </div>
                )}

                <div className={styles.footerLinkText}>
                  <Link to="/#">Contact</Link>
                </div>
              </div>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={24}
            className={styles.footerColData}
          >
            <div>
              <div className={styles.footerSectionHeader}>Ligal</div>
              <div style={{ marginTop: '15px' }}>
                <div className={styles.footerLinkText}>
                  <Link to="/#">Terms & Conditions</Link>
                </div>
                <div className={styles.footerLinkText}>
                  <Link to="/#">Privacy Policy</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className={styles.copyrightTxt}>@Copyright Gigsure, All Rights Reserved</div>
      </section>
    </>
  )
}

export default Footer

export const useAuth = () => {
  const isTokenAvail = localStorage.getItem('token')
  const isUserIdAvail = localStorage.getItem('userId')
  return !!(isTokenAvail && isUserIdAvail)
}

let nullCheck = [
  '',
  'NULL',
  'Null',
  'null',
  'Undefined',
  'UNDEFINED',
  'undefined',
  null,
  undefined,
  'N/A',
  'NA',
]

export const NullTextCheckForm = params => {
  return nullCheck?.includes(params) ? '' : params
}

export const disableScroll = e => {
  e.target.blur()
}

export const googleTranslateElementInit = () => {
  setCookie('googtrans', '/en/hi', 1)
  new window.google.translate.TranslateElement(
    {
      pageLanguage: 'en',
      includedLanguages: 'hi,en',
      autoDisplay: true,
      layout: window.google.translate.TranslateElement.InlineLayout.VERTICLE,
    },
    'google_translate_element'
  )
  function setCookie(key, value, expiry) {
    var expires = new Date()
    expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000)
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString()
  }
}

export const getToken = () => {
  if (
    !window.localStorage.getItem('userId') ||
    !window.localStorage.getItem('token') ||
    !window.localStorage.getItem('DateTimeTokenFront')
  ) {
    window.location.href = '/'
    window.localStorage.clear()
  }
}

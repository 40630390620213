import { Button, message, Drawer } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import menuIcon from '../../assets/image/menu.png'
import { Helmet } from 'react-helmet'
import { SiteConstants } from '../../store/constants/siteconstants'
import moment from 'moment'
import styles from '../../screens/LandingPage/LandingPage.module.css'
import { useNavigate } from 'react-router-dom'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import '../../screens/LandingPage/LandingPage.scss'

// import './LandingPage.scss'
// import LOGO from '../../assets/image/GigSURE.png'
// import './style.css'

const MainHeader = () => {
  const [isDrawerVisible, setDrawerVisible] = useState(false)
  const { width } = useWindowDimensions()

  // States
  const location = useLocation()
  const [current, setCurrent] = useState(location.pathname)

  const navigate = useNavigate()

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname)
      }
    }
  }, [location, current])

  // 1 Min After CHECK
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        window.localStorage.getItem('userId') &&
        window.localStorage.getItem('DateTimeTokenFront') &&
        window.localStorage.getItem('token')
      ) {
        let DateTimeToken = new Date(window.localStorage.getItem('DateTimeTokenFront'))
        let CurrentDateTime = new Date(moment().format('YYYY-MM-DD HH:mm:ss'))

        if (CurrentDateTime >= DateTimeToken) {
          message.error('Your session is expired, please login again')
          onLogout()
        }
      }
    }, 60000)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  const onLogout = () => {
    window.localStorage.clear()
    navigate('/login')
  }

  const CustomTitle = () => {
    if (current === '/') {
      return `Membership proposal form - ${SiteConstants.gigSureLabel}`
    } else if (current === '/login') {
      return `Login - ${SiteConstants.gigSureLabel}`
    } else if (current === '/dashboard') {
      return `Dashboard - ${SiteConstants.gigSureLabel}`
    } else {
      if (current.includes('/success')) {
        return `Success - ${SiteConstants.gigSureLabel}`
      }
      if (current.includes('/verifyOtp')) {
        return `Verify otp - ${SiteConstants.gigSureLabel}`
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{CustomTitle()}</title>
      </Helmet>

      <section className={styles.main_Nav_wrapper}>
        <div className={styles.nav_wrapper_div}>
          <Link to="/">
            <div>
              <img src="/img/GigsureLogo.png" alt="" width="100%" />
            </div>
          </Link>

          {width >= 1200 ? (
            <>
              <div className={styles.nav_subMenu_wrapper}>
                <div>
                  <Link to="/#">HOME</Link>
                </div>
                <div>
                  <Link to="/#">ABOUT</Link>
                </div>
                <div>
                  <Link to="/#">INSURANCE</Link>
                </div>

                {!window.localStorage.getItem('token') &&
                !window.localStorage.getItem('userId') &&
                !window.localStorage.getItem('DateTimeTokenFront') ? (
                  <div>
                    <Link to="/login">RENEW</Link>
                  </div>
                ) : (
                  <div>
                    <Link to="/dashboard">DASHBOARD</Link>
                  </div>
                )}
              </div>

              <div className={styles.nav_btn_div}>
                <Button className="nav_contactBtn">
                  <Link to="/#">CONTACT</Link>
                </Button>

                {window.localStorage.getItem('token') &&
                window.localStorage.getItem('userId') &&
                window.localStorage.getItem('DateTimeTokenFront') ? (
                  <>
                    <Button className="nav_loginBtn" onClick={() => onLogout()}>
                      LOGOUT
                    </Button>
                  </>
                ) : (
                  <>
                    <Button className="nav_loginBtn">
                      <Link to="/login">LOGIN</Link>
                    </Button>
                  </>
                )}
              </div>
            </>
          ) : (
            <div onClick={() => setDrawerVisible(true)}>
              <img src={menuIcon} className={styles.menuIcon} />
            </div>
          )}
        </div>

        <Drawer
          placement="left"
          closable={false}
          onClose={() => setDrawerVisible(false)}
          visible={isDrawerVisible}
          className="headerDrawer"
        >
          <div>
            <div className={styles.siderMenu_wrapper}>
              <div>
                <Link to="/">HOME</Link>
              </div>
              <div>
                <Link to="/#">ABOUT</Link>
              </div>
              <div>
                <Link to="/#">INSURANCE</Link>
              </div>
              {!window.localStorage.getItem('token') &&
              !window.localStorage.getItem('userId') &&
              !window.localStorage.getItem('DateTimeTokenFront') ? (
                <div>
                  <Link to="/login">RENEW</Link>
                </div>
              ) : (
                <div>
                  <Link to="/dashboard">DASHBOARD</Link>
                </div>
              )}
            </div>
            <div className={styles.sider_btn_div}>
              <Button className="sider_contactBtn">
                <Link to="/#">CONTACT</Link>
              </Button>

              {window.localStorage.getItem('token') &&
              window.localStorage.getItem('userId') &&
              window.localStorage.getItem('DateTimeTokenFront') ? (
                <>
                  <Button className="sider_logoutBtn" onClick={() => onLogout()}>
                    LOGOUT
                  </Button>
                </>
              ) : (
                <>
                  <Button className="sider_loginBtn">
                    <Link to="/login">LOGIN</Link>
                  </Button>
                </>
              )}
            </div>
          </div>
        </Drawer>
      </section>
    </>
  )
}
export default MainHeader

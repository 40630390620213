import './App.css'
import { Layout } from 'antd'
import HeaderComponent from './components/header'
import FooterComponent from './components/footer'
import { createRef } from 'react'
import RoutesComponent from './router'
const { Content } = Layout
import styles from './screens/LandingPage/LandingPage.module.css'
// import LandingPage from './screens/LandingPage'

function App() {
  const appRef = createRef()
  return (
    // <LandingPage />
    <div ref={appRef}>
      <Layout>
        <div className={styles.Main_wrapper}>
          <HeaderComponent />
          <Content className="mainContainer">
            <RoutesComponent appRef={appRef} />
          </Content>
          <div className="footer">
            <FooterComponent />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default App

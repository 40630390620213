import { Badge, Button, Image, Radio, Col, Row } from 'antd'
import styles from '../../screens/LandingPage/LandingPage.module.css'

import { Link } from 'react-router-dom'

function Home() {
  return (
    <>
      <section className={styles.TittleSection}>
        <Row className={styles.tittle_row_section}>
          <Col xxl={12} xl={12} lg={12}>
            <div className={styles.firstCol_wrapper}>
              <div className={styles.tittleHeadText}>Let&apos;s plan your</div>
              <div className={styles.tittleHeadText}>INSURANCE</div>
              <div className={styles.tittleText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
              {/* <button className="getInTouchBtn">GET IN TOUCH</button> */}

              <Button className="getInTouchBtn">
                <Link to="#">GET IN TOUCH</Link>
              </Button>
            </div>
          </Col>
          <Col className="tittleImageCol" xxl={12} xl={12} lg={12}>
            <Image
              className={styles.tittleImageStyle}
              src="/img/tittleGrp.png"
              preview={false}
            />
          </Col>
        </Row>
      </section>
      <section className={styles.customCard_section}>
        <Row gutter={24} className={styles.cardRow}>
          <Col xxl={8} xl={8} lg={8} md={12} sm={24}>
            <div className={styles.cardWrapper}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div className={styles.eclipseImage}>
                  <Image
                    preview={false}
                    src="/img/Layer_1.png"
                    className={styles.insideEclipseImage}
                  />
                </div>
                <div className={styles.countImageDiv}>
                  <div className={styles.countStyle}>1</div>
                </div>
              </div>
              <div className={styles.cardPadding}>
                <div className={styles.cardHeader}>Safe your money</div>
                <div className={styles.cardDetailText}>
                  <span>Lorem ipsum dolor sit amet, consectetur </span>
                  <span>adipiscing elit. Nulla non risus in magna</span>
                  <span>malesuada congue in quis nibh.</span>
                </div>
              </div>
            </div>
          </Col>
          <Col className={styles.customCardNo2} xxl={8} xl={8} lg={8} md={12} sm={24}>
            <div className={styles.cardWrapper}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div className={styles.eclipseImage}>
                  <Image
                    preview={false}
                    src="/img/Layer_2.png"
                    className={styles.insideEclipseImage}
                  />
                </div>
                <div className={styles.countImageDiv}>
                  <div className={styles.countStyle}>2</div>
                </div>
              </div>
              <div className={styles.cardPadding}>
                <div className={styles.cardHeader}>Get free quote</div>
                <div className={styles.cardDetailText}>
                  <span>Lorem ipsum dolor sit amet, consectetur </span>
                  <span>adipiscing elit. Nulla non risus in magna</span>
                  <span>malesuada congue in quis nibh.</span>
                </div>
              </div>
            </div>
          </Col>
          <Col className={styles.customCardNo3} xxl={8} xl={8} lg={8} md={12} sm={24}>
            <div className={styles.cardWrapper}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div className={styles.eclipseImage}>
                  <Image
                    preview={false}
                    src="/img/Layer_3.png"
                    className={styles.insideEclipseImage}
                  />
                </div>
                <div className={styles.countImageDiv}>
                  <div className={styles.countStyle}>3</div>
                </div>
              </div>
              <div className={styles.cardPadding}>
                <div className={styles.cardHeader}>Fast & reliable</div>
                <div className={styles.cardDetailText}>
                  <span>Lorem ipsum dolor sit amet, consectetur </span>
                  <span>adipiscing elit. Nulla non risus in magna</span>
                  <span>malesuada congue in quis nibh.</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section className={styles.aboutSection}>
        <Row className={styles.aboutSectionRow}>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24}>
            <Image src="/img/aboutImage.png" preview={false} />
          </Col>
          <Col
            className={styles.aboutSectionTextDiv}
            xxl={12}
            xl={12}
            lg={24}
            md={24}
            sm={24}
          >
            <div>
              <div className={styles.aboutText}>About Company</div>
              <div className={styles.aboutHeader}>
                <div> We&apos;re Trusted and Professional </div>
                <div>Insurance Company</div>
              </div>
              <div className={styles.radioBtnGrpDiv}>
                <Radio.Group
                  className="radioBtn_group"
                  buttonStyle="solid"
                  style={{ border: 'none' }}
                  defaultValue={'About'}
                >
                  <Radio.Button className="about_btn" value="About">
                    About
                  </Radio.Button>
                  <Radio.Button className="mission_btn" value="Mission">
                    Mission
                  </Radio.Button>
                  <Radio.Button className="vision_btn" value="Vision">
                    Vision
                  </Radio.Button>
                </Radio.Group>
              </div>
              <div className={styles.aboutCompanyTxt}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non risus
                in magna malesuada congue in quis nibh. Fusce eu leo id nunc condimentum
                aliquam. Nullam fringilla, mi cursus ultricies ultricies, mi nisl mattis
                tortor, quis molestie risus neque sed tellus.
              </div>
              <div className={styles.badgeDiv}>
                <Badge
                  status="success"
                  text="Lorem ipsum dolor sit amet"
                  className="badgeText"
                />
                <Badge
                  status="success"
                  text="Lorem ipsum dolor sit amet"
                  className="badgeText"
                />
                <Badge
                  status="success"
                  text="Lorem ipsum dolor sit amet"
                  className="badgeText"
                />
              </div>
              {/* <Button className="moreAboutBtn">MORE ABOUT</Button> */}

              <Button className="moreAboutBtn">
                <Link to="#">MORE ABOUT</Link>
              </Button>
            </div>
          </Col>
        </Row>
      </section>
      <section className={styles.insuranceSection}>
        <div>
          <div className={styles.insuranceHeadText}>Insurance</div>
          <div className={styles.insuranceDetailText}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry&apos;s standard
          </div>
        </div>
        <Row gutter={5} className={styles.insuranceRow}>
          <Col xxl={8} xl={8} lg={8} md={24}>
            <div className={styles.cardDesign}>
              <Image src="/img/setting.png" preview={false} />
              <div className={styles.insuranceCardHeader}>Business Insurance</div>
              <div className={styles.insuraceCardText}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry&apos;s standard
              </div>
            </div>
          </Col>
          <Col className={styles.insuranceRowNo2} xxl={8} xl={8} lg={8} md={24}>
            <div className={styles.cardDesign}>
              <Image src="/img/setting.png" preview={false} />
              <div className={styles.insuranceCardHeader}>Business Insurance</div>
              <div className={styles.insuraceCardText}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry&apos;s standard
              </div>
            </div>
          </Col>
          <Col className={styles.insuranceRowNo3} xxl={8} xl={8} lg={8} md={24}>
            <div className={styles.cardDesign}>
              <Image src="/img/setting.png" preview={false} />
              <div className={styles.insuranceCardHeader}>Business Insurance</div>
              <div className={styles.insuraceCardText}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry&apos;s standard
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={5} className={styles.insuranceRow}>
          <Col xxl={8} xl={8} lg={8} md={24}>
            <div className={styles.cardDesign}>
              <Image src="/img/setting.png" preview={false} />
              <div className={styles.insuranceCardHeader}>Business Insurance</div>
              <div className={styles.insuraceCardText}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry&apos;s standard
              </div>
            </div>
          </Col>
          <Col className={styles.insuranceRowNo2} xxl={8} xl={8} lg={8} md={24}>
            <div className={styles.cardDesign}>
              <Image src="/img/setting.png" preview={false} />
              <div className={styles.insuranceCardHeader}>Business Insurance</div>
              <div className={styles.insuraceCardText}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry&apos;s standard
              </div>
            </div>
          </Col>
          <Col className={styles.insuranceRowNo3} xxl={8} xl={8} lg={8} md={24}>
            <div className={styles.cardDesign}>
              <Image src="/img/setting.png" preview={false} />
              <div className={styles.insuranceCardHeader}>Business Insurance</div>
              <div className={styles.insuraceCardText}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry&apos;s standard
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section className={styles.benefitSection}>
        <div className={styles.benefitHeader}>Benefits</div>
        <div className={styles.benefitDetailText}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry&apos;s standard
        </div>
        <div className={styles.benefitCardWrapperDiv}>
          <div className={styles.benefitImageDiv}>
            <Image
              src="/img/benefitimage.png"
              preview={false}
              className={styles.benefitImage}
            />
          </div>
          <div className={styles.benefitCardTextDivWrapper}>
            <div className={styles.benefitWrapperDiv}>
              <div className={styles.benefitCardDiv}>
                <div className={styles.benefitCardHeader}>
                  Lorem ipsum dolor sit amet, consectetur
                </div>
                <div className={styles.benefitCardText}>
                  Fully work transparency between employee and employer. Manage your data
                  and projects with regular work updates.
                </div>
              </div>
            </div>
            <div className={styles.benefitWrapperDiv1}>
              <div className={styles.benefitCardDiv}>
                <div className={styles.benefitCardHeader}>
                  Lorem ipsum dolor sit amet, consectetur
                </div>
                <div className={styles.benefitCardText}>
                  Fully work transparency between employee and employer. Manage your data
                  and projects with regular work updates.
                </div>
              </div>
            </div>
            <div className={styles.benefitWrapperDiv}>
              <div className={styles.benefitCardDiv}>
                <div className={styles.benefitCardHeader}>
                  Lorem ipsum dolor sit amet, consectetur
                </div>
                <div className={styles.benefitCardText}>
                  Fully work transparency between employee and employer. Manage your data
                  and projects with regular work updates.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.talkToSection}>
        <div>
          <div className={styles.talkToHeader}>Talk to Experts</div>
          <Row gutter={10} className={styles.talkToRow}>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
              <div className={styles.clientCardDiv}>
                <Image
                  src="/img/client1.png"
                  preview={false}
                  className={styles.clientImage}
                />
                <div className={styles.clientName}>Bricon Gionhan</div>
              </div>
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={24}
              className={styles.talkToExpertRowItem1}
            >
              <div className={styles.clientCardDiv}>
                <Image
                  src="/img/client2.png"
                  preview={false}
                  className={styles.clientImage}
                />
                <div className={styles.clientName}>Krina Hemton</div>
              </div>
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={24}
              className={styles.talkToExpertRowItem}
            >
              <div className={styles.clientCardDiv}>
                <Image
                  src="/img/client3.png"
                  preview={false}
                  className={styles.clientImage}
                />
                <div className={styles.clientName}>Jacob Multrion</div>
              </div>
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={24}
              className={styles.talkToExpertRowItem}
            >
              <div className={styles.clientCardDiv}>
                <Image
                  src="/img/client4.png"
                  preview={false}
                  className={styles.clientImage}
                />
                <div className={styles.clientName}>Mellisa Kelinson</div>
              </div>
            </Col>
          </Row>
        </div>
        <Row gutter={10} className={styles.reportRow}>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
            <div className={styles.reportCardDiv}>
              <div className={styles.reportHeader}>+500</div>
              <div className={styles.reportDetailText}>Projects Completed</div>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={24}
            className={`${styles.reportCol} ${styles.reportRowData}`}
          >
            <div className={styles.reportCardDiv}>
              <div className={styles.reportHeader}>+2k</div>
              <div className={styles.reportDetailText}>Satisfied Customers</div>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={24}
            className={`${styles.reportCol} ${styles.reportRowDataCol}`}
          >
            <div className={styles.reportCardDiv}>
              <div className={styles.reportHeader}>99%</div>
              <div className={styles.reportDetailText}>Claim Success Rates</div>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={24}
            className={`${styles.reportCol} ${styles.reportRowDataCol}`}
          >
            <div className={styles.reportCardDiv}>
              <div className={styles.reportHeader}>+80</div>
              <div className={styles.reportDetailText}>Professional Team</div>
            </div>
          </Col>
        </Row>
      </section>
      <section className={styles.contactUsSection}>
        <div className={styles.contactHeader}>If You Have Any Questions Contact Us</div>
        <Button className="contactBtn">CONTACT</Button>
      </section>
    </>
  )
}

export default Home
